import store from '../store';

export default async (to, from, next) => {

    document.title = `${to.name.toUpperCase()} - ${process.env.VUE_APP_TITLE}`;
    if(to.name !== 'login' && to.name !== 'logout' && !store.getters['auth/hasToken'] && !store.getters['auth/hasHash']){
        try{
            await store.dispatch('auth/ActionCheckToken');
            await store.dispatch('auth/ActionCheckHash');
            next({ name: to.name });
        }catch (err){
            next ( { name: 'login' });
        }
    } 
    else{
        if(to.name === 'login' && !store.getters['auth/hasToken'] && !store.getters['auth/hasHash']){
            await store.dispatch('auth/loadData');
        }
        if(to.name === 'login' && store.getters['auth/hasToken'] && store.getters['auth/hasHash']){
            next ( { name: 'Dashboard' });
        }
        if(to.name === 'Gestão de Usuários' && store.getters['auth/hasToken'] && store.getters['auth/hasHash'] && store.getters['auth/userType'] != 'administrator'){
            next ( { name: 'Dashboard' });
        }
        if(to.name === 'Gestão de Empresas' && store.getters['auth/hasToken'] && store.getters['auth/hasHash'] && store.getters['auth/userType'] != 'administrator'){
            next ( { name: 'Dashboard' });
        }
        else{
            next ();
        }
    }
}