

import CryptoJS from 'crypto-js'

export function generateToken(username, password) {
   // geração do código aleatório
    const codeBytes = CryptoJS.lib.WordArray.random(16);
    const code = codeBytes.toString(CryptoJS.enc.Hex);

    // chave secreta do usuário
    const secret = username + ':' + password;

    // método para calcular o HMAC-SHA256
    const hmacSha256 = function(secret, message) {
        const hmac = CryptoJS.HmacSHA256(message, secret);
        return hmac.toString(CryptoJS.enc.Hex);
    }

    // geração do token de acesso
    const token = hmacSha256(secret, code);

    console.log('Token de acesso: ' + token);

    return token;
  }
