import axios from 'axios';

export async function getFullAddress(cep) {
    if (cep != "") {
        cep = cep.replace(/\D/g, '');
        var validacep = /^[0-9]{8}$/;
       
        if (validacep.test(cep)) {
            const instance = axios.create({
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'application/json;charset=utf-8'
                },
                withCredentials: false
            });

            return instance.get(`https://viacep.com.br/ws/${cep}/json/`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }
}


