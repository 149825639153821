import {servicesApi} from '@/http';
import * as types from './mutation-types';
//import * as storage from '../storage'
import  * as objectsFunctions from '@/helpers/objectFunctions'
import store from '@/store';

export const ActionGetRoutes = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.rotaCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_ROUTES, res.data.data);
    }

  })
}

export const ActionNewRoute = (_, payload) =>{

    payload.hash = store.state.auth.hash;
    const data = objectsFunctions.ConvertToUrlobj(payload);

    return servicesApi.routing.rotaInc({data:data}).then(res => {
      if(parseInt(res.data.cod) > 0){
        throw {msg:res.data.msg};
      }
      else if(res.status != 200 || !res.data){
        throw {msg:'Erro ao incluir a rota'};
      }
  
      return res.data.last_id;
    });
}

export const ActionUpdateRoute = (_, payload) =>{

    payload.hash = store.state.auth.hash;
    const data = objectsFunctions.ConvertToUrlobj(payload);

    return servicesApi.routing.rotaUpdate({data:data}).then(res => {
      if(res.data.cod > 0 || !res.data){
        throw {msg:'Erro ao editar a rota'};
      }
    });
}

export const ActionDeleteRoute = (_, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.rotaDelete({  
    data:data
  }).then(res => {
    if(res.data.cod > 0 || !res.data){
      throw {msg:'Erro ao deletar a rota'};
    }
  });
}

export const ActionGetPointsByIdRoute = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.pontoCons_rota({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_POINTS, res.data.data);
    }

  });
}

export const ActionNewPoint = (_, payload) =>{

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.pontoInc({data:data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o ponto'};
    }

  });
}

export const ActionDeletePoint = (_, payload) =>{

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.pontoDelete({data:data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao deletar o ponto'};
    }

  });
}

export const ActionGetDeviceRoutes = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.rotaCons_idDispositivo({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_ROUTES_DEVICES, res.data.data);
    }
    else{
      commit(types.SET_ROUTES_DEVICES, []);
    }

  })
}

export const ActionAddDeviceRoute = (_, payload) =>{

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.dispositivo_rotaInc({data:data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao associar o dispostivo a rota'};
    }

  });
}

export const ActionRemoveDeviceRoute = (_, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.routing.dispositivo_rotaDelete({data:data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao desassociar o dispostivo a rota'};
    }

  });
}
