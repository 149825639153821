export default {
    rotaInc: { method: 'post', url: 'rotaInc', port: '5001' },
    rotaUpdate: { method: 'post', url: 'rotaUpdate', port: '5001' },
    rotaDelete: { method: 'post', url: 'rotaDelete', port: '5001' },
    rotaCons_name: { method: 'post', url: 'rotaCons_name', port: '5001' },
    rotaCons_id: { method: 'post', url: 'rotaCons_id', port: '5001' },
    pontoInc: { method: 'post', url: 'pontoInc', port: '5001' },
    pontoUpdate: { method: 'post', url: 'pontoUpdate', port: '5001' },
    pontoDelete: { method: 'post', url: 'pontoDelete', port: '5001' },
    pontoCons_rota: { method: 'post', url: 'pontoCons_rota', port: '5001' },
    pontoCons_id: { method: 'post', url: 'pontoCons_id', port: '5001' },
    rotaCons_idDispositivo: { method: 'post', url: 'rotaCons_idDispositivo', port: '5001' },
    dispositivo_rotaCons_id: { method: 'post', url: 'dispositivo_rotaCons_id', port: '5001' },
    dispositivo_rotaInc: { method: 'post', url: 'dispositivo_rotaInc', port: '5001' },
    dispositivo_rotaDelete: { method: 'post', url: 'dispositivo_rotaDelete', port: '5001' },
}