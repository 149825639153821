import services from '@/http';
import { servicesApi } from '@/http';
import * as types from './mutation-types';
import * as storage from '../storage';
import * as objectsFunctions from '@/helpers/objectFunctions';
import store from '@/store';

export const ActionDoLogin = ({ dispatch }, payload) =>{

    const data = objectsFunctions.ConvertToUrlobj(payload);

    return services.auth.login({data}).then(res => {
        
        let user = {};

        user = res.data;

        if(user.administrator){
            user.type = 'administrator';
        }
        else if(res.data.userLimit == -1){
            user.type = 'manager'; 
        }
        else{
            user.type = 'default'; 
        }

        dispatch('ActionSetUser' , user); 
        //dispatch('ActionSetToken' , res.data.token);

    }).catch((error) => {
        if(error.code == 401){
            throw 'E-mail ou senha inválidos';
        }
        else{
            throw 'Ocorreu algum erro ao tentar realizar o login';
        }
    })
}

export const ActionCheckToken = ({ dispatch, state }) => {
    if(state.token){
        return Promise.resolve(state.token);
    }

    const token = storage.getLocalToken();
    if(!token){
        dispatch('ActionSignOut');
        return Promise.reject('Token Inválido');
    }

    dispatch('ActionSetToken', token);
    return dispatch('ActionLoadSession', token);
}

export const ActionLoadSession = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {

        let user = {}
        
        return services.auth.session({params:{ token : payload}}).then(res => {
            
            user = res.data;

            if(user.administrator){
                user.type = 'administrator';
            }
            else if(res.data.userLimit == -1){
                user.type = 'manager'; 
            }
            else{
                user.type = 'default'; 
            }

            dispatch('ActionSetUser' , user); 

            resolve();
        }).catch((error) => {

            if(error.code == 401){
                dispatch('ActionSignOut');
                reject('E-mail ou senha inválidos');
            }
            else{
                dispatch('ActionSignOut');
                reject('ocorreu algum erro ao tentar realizar o login');
            }
    
        })
    })
}

export const ActionSetUser = ({ commit }, payload) => {
    storage.setLocalUser(payload);
    commit(types.SET_USER, payload);
}


export const ActionSetToken = ({ commit }, payload) => {
    storage.setLocalToken(payload);
    storage.setHeaderToken(payload);
    commit(types.SET_TOKEN, payload);
  }


export const ActionSetHash = ({ commit }, payload) => {
    storage.setLocalHash(payload);
    commit(types.SET_HASH, payload);
}


export const ActionSetOrion = ({ commit }, payload) => {
    storage.setLocalOrion(payload);
    commit(types.SET_ORION, payload);
}

export const ActionSignOut = ({ dispatch }) => {

    if(storage.getLocalToken())
    {
        services.auth.logout()
        .catch((error) => {
            throw error;
        })
    }

    if(storage.getLocalHash())
    {
        const hash = {
            hash:storage.getLocalHash(),
        };
        const data = objectsFunctions.ConvertToUrlobj(hash);
        servicesApi.auth.logout({data}).then(res => {
            if(parseInt(res.data.cod) > 0 || !res.data){
                throw res.data.msg;
            }
        })
    }

    storage.setHeaderToken('');
    storage.deleteLocalToken();
    storage.deleteLocalUser();
    storage.deleteLocalHash();
    storage.deleteLocalOrion();
    
    dispatch('ActionSetUser' , '');
    dispatch('ActionSetToken' , '');  
    dispatch('ActionSetHash' , ''); 
    dispatch('ActionSetOrion' , ''); 
}

export const loadData = ({ dispatch }) => {
    try{

        if(storage.getLocalToken() && storage.getLocalHash())
        {
            dispatch('ActionLoadSession', storage.getLocalToken());
            dispatch('ActionSetUser' , storage.getLocalUser()); 
            dispatch('ActionSetToken' , storage.getLocalToken());
            dispatch('ActionSetHash' , storage.getLocalHash());
            dispatch('ActionSetOrion' , storage.getLocalOrion());
        }
        else{
            throw 'Usuário não autorizado';
        }
    }catch(er){
        dispatch('ActionSignOut');
    }
}

export const ActionDoLoginHash = ({ dispatch }, payload) => {

    const data = objectsFunctions.ConvertToUrlobj(payload);

    return servicesApi.auth.login({data}).then(res => {

        if(parseInt(res.data.cod) > 0 || !res.data){
           throw res.data.msg;
        }
        else{
            dispatch('ActionSetHash', res.data.hash); 
            dispatch('ActionSetOrion', res.data.ususario[0]); 
        }
    });
}

export const ActionCheckHash = ({ dispatch, state }) => {
    if(state.hash){
        return Promise.resolve(state.hash);
    }

    const hash = storage.getLocalHash();
    if(!hash){
        dispatch('ActionSignOut');
        return Promise.reject('Hash Inválida');
    }

    dispatch('ActionSetHash', hash);
    return dispatch('ActionLoadSessionHash', hash);
}

export const ActionLoadSessionHash = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {

        const hash = {
            hash:payload,
        };
    
        const data = objectsFunctions.ConvertToUrlobj(hash);
    

        return servicesApi.auth.hash({data}).then(res => {

            if(parseInt(res.data.cod) > 0 || !res.data){
                dispatch('ActionSignOut');
                reject(res.data.msg);
            }
            else{
                dispatch('ActionSetHash', payload); 
                dispatch('ActionSetOrion', res.data.data[0]); 
            }

            resolve();
        }).catch((error) => {

            if(error.code == 401){
                dispatch('ActionSignOut');
                reject('E-mail ou senha inválidos');
            }
            else{
                dispatch('ActionSignOut');
                reject('ocorreu algum erro ao tentar realizar o login');
            }
    
        })
    })
}

export const ActionAlterarSenha = (_, payload) => {

    payload.hash = store.state.auth.hash;
  
    const data = objectsFunctions.ConvertToUrlobj(payload);
  
    return servicesApi.auth.alterarsenha({data}).then(res => {
  
      if(parseInt(res.data.cod) > 0){
        throw {msg:res.data.msg};
      }
      else if(res.status != 200 || !res.data){
        throw {msg:'Erro ao alterar a senha'};
      }
    })  
}

export const ActionGenerateToken = (_, payload) => {

    const data = objectsFunctions.ConvertToUrlobj(payload);
    
    return services.auth.generateToken({data}).then(res => {
        return res.data;
    }).catch((error) => {

        throw {msg:error};
    });
}