import { h, resolveComponent } from 'vue'
import {routes as groups} from '../groups/'
export default [
  {
    path: '/dispositivos',
    name: 'Dispositivos',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/dispositivos/gestao',
    children: [
      {
        path: '/dispositivos/gestao',
        name: 'Gestão de Dispositivos',
        component: () => import('./views/homeDevice')
      },
      ...groups
    ]
  },
]