import { toRaw } from 'vue'
import qs from 'qs'
import moment from 'moment';

export function ConvertToUrlobj(obj) {
    const raw = toRaw(obj)
    return qs.stringify(raw)
}

export function dateFormat(value, format = 'DD/MM/YYYY HH:mm:ss') {
    if (value) {
        return moment(String(value)).format(format)
    }
}

export function ConvertToObj(obj) {
    const raw = toRaw(obj)
    return raw
}


export function convertDate(value) {
    const dateTime = value.split(' ');

    console.log(dateTime[0].search('-'));

    const date = dateTime[0].search('-') != -1 ? dateTime[0].split('-').reverse().join('/') : dateTime[0].split('/').reverse().join('-');
    const time = dateTime[1];
    return date + ' ' + time;
}

export function hasAllProperties(obj, props) {

    if (!obj) {
        return false;
    }
    for (var prop in props) {
        if (Object.prototype.hasOwnProperty.call(obj, props[prop])) {
            return true;
        }
    }
    return false;
}


function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function convertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    //minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    //hours = hours % 24;

    hours = hours > 0 ? `${hours}h:` : '';

    return `${hours}${padTo2Digits(minutes)}min`;
}

//Validação para permitir apenas números no formulário
export function onlyIntNumber($event) {
    $event.target.value = $event.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
}

export function onlyIntNumberNegative($event) {
    $event.target.value = $event.target.value.replace(/-[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0').replace('-0', '0');
    if ($event.target.value < -1) {
        $event.target.value = -1;
    }
}


