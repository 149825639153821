import * as types from './mutation-types'
import {servicesApi} from '@/http';
import * as objectsFunctions from '@/helpers/objectFunctions';
import store from '@/store';

export const ActionGetFuels = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.fuel.abastecimentoCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_FUEL, res.data.data);
    }

  })
}

export const ActionAbastecimentoCons_id = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.fuel.abastecimentoCons_id({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return null;
    }
    else if(res.status != 200 || !res.data){
      return null;
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionAbastecimentoInc = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.fuel.abastecimentoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir a manutenção'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionAbastecimentoUpdate = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.fuel.abastecimentoUpdate({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar a ordem de combustível'};
    }

    return res.data.last_id;
    
  })
}

export const ActionAbastecimentoDelete = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.fuel.abastecimentoDelete({  
    data:data
  }).then(res => {
    if(res.data.cod > 0 || !res.data){
      throw {msg:'Erro ao deletar a manutenção'};
    }
  })
}

export const ActionGetFuelTypes = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.maintenances.tipo_manutencaoCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_FUEL_TYPES, res.data.data);
    }
    else{
      commit(types.SET_FUEL_TYPES, []);
    }

  })
}

export const ActionComboFuelTypes = ({ state } ) =>{

  let comboBoxList = [
    { label: 'Selecione', value: '' }
  ];

  state.types.forEach(type => {
    comboBoxList[type['id']] = { label: type['nome'], value: type['id'].toString()}
  });
  
  return comboBoxList;
}