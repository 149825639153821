import axios from 'axios';
import services from './services';
import servicesApi from './servicesApi';
import {response, error} from './interceptors';


axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const baseUrl = process.env.VUE_APP_ROOT_API;

const setOrionToken = token => {
    if(token){
        //axios.defaults.headers.common['Authorization'] = `basic ${token}`
    }
}

Object.keys(services).map(service =>{
    Object.keys(services[service]).map(ss =>{
        
        //const port = services[service][ss]['port']
        const url = services[service][ss]['url'];
        
        axios.defaults.baseURL = baseUrl+url;
        services[service][ss] = axios.create(services[service][ss]);

        services[service][ss].interceptors.response.use(response,error);
    })
})


axios.defaults.withCredentials = false;
const baseUrlApi = process.env.VUE_APP_HOST_ORION;

Object.keys(servicesApi).map(serviceApi =>{
    Object.keys(servicesApi[serviceApi]).map(ss =>{
        
        const port = servicesApi[serviceApi][ss]['port'];
        const url = servicesApi[serviceApi][ss]['url'];
        
        axios.defaults.baseURL = `${baseUrlApi}:${port}/api/${url}/`;
        servicesApi[serviceApi][ss] = axios.create(servicesApi[serviceApi][ss]);

        //servicesApi[serviceApi][ss].interceptors.response.use(response,error);
    })
})

export default services;
export { setOrionToken, servicesApi };

