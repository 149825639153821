import { store as auth } from '@/modules/auth'
import { store as  geolocation} from '@/modules/geolocation'
import { store as  devices} from '@/modules/devices'
import { store as  vehicles} from '@/modules/vehicles'
import { store as  groups} from '@/modules/groups'
import { store as  drivers} from '@/modules/drivers'
import { store as  users} from '@/modules/users'
import { store as  geofences} from '@/modules/geofences'
import { store as  reports} from '@/views/reports'
import { store as  companies} from '@/modules/companies'
import { store as  notifications} from '@/modules/notifications'
import { store as  routing} from '@/modules/routing'
import { store as  fuel} from '@/modules/routing'
import { store as  maintenances} from '@/modules/maintenances'
export default {
    auth,
    geolocation,
    devices,
    vehicles,
    groups,
    drivers,
    users,
    geofences,
    reports,
    companies,
    notifications,
    routing,
    fuel,
    maintenances
}