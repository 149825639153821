import * as types from './mutation-types'

export default{
    [types.SET_POSITIONS] (state, payload){
        state.positions = payload
    },
    [types.PUT_POSITION] (state, payload){
        state.positions.push(payload)
    },
    [types.SET_FOLLOW] (state, payload){
        state.follow = payload
    },
    [types.PUT_ADDRESS] (state, payload){
        state.positions[payload.key]['address'] = payload.value
    },
    [types.SET_POINTS] (state, payload){
        state.points = payload
    },
    [types.SET_SHOW_POINTS] (state, payload){
        state.showPoints = payload
    },
    [types.SET_CURRENT_STATE_REPORT] (state, payload){
        state.currentStateReport = payload
    },
    [types.SET_TRAIL] (state, payload){
        state.trail = payload
    }
}