import { createRouter, createWebHistory} from 'vue-router'
import Routes from './routes'
import beforeEach from './beforeEach'


const routes = Routes

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(beforeEach)

export default router
