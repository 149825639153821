import services from '@/http';
import * as types from './mutation-types';
//import * as storage from '../storage'
//import  * as objectsFunctions from '@/helpers/objectFunctions'
import store from '@/store';

export const ActionGetNotifications = ({commit}, payload) =>{
  const user = store.state.auth.user;

  const urlParams = {};

  if (user.type == 'default') {
    urlParams.userId = user.id;
  }

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }
  else if (user.type != 'default'){
    urlParams.all = true;
  }


  return services.notifications.notificationList({params:urlParams}).then(res => {
      
    commit(types.SET_NOTIFICATIONS, res.data);

  }).catch((error) => {

      if(error.code == 400){
          throw 'Sem permissão para a listagem de notificações'
      }
      else{
          throw 'Ocorreu algum erro ao tentar realizar a listagem de notificações'
      }
  })  
}

export const ActionGetNotificationsParam = (_, payload) => {

  let urlParams = {};

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }

  return services.notifications.notificationList({params:urlParams}).then(res => {

    return res.data;

  }).catch((error) => {
    if (error.code == 400) {
      throw 'Sem permissão para a listagem de notificações'
    }
    else {
      throw 'Ocorreu algum erro ao tentar realizar a listagem de notificações'
    }
  })
}


export const ActionNewNotification = (_, payload) =>{

    return services.notifications.newNotification({data:payload}).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao inserir a notificação'};
      }
    }).catch((error) => {
        throw error;
    })  
}

export const ActionUpdateNotification = (_, payload) =>{

    const baseUrl = process.env.VUE_APP_ROOT_API
    return services.notifications.updateNotification({  
        baseURL: baseUrl,
        url: `notifications/${payload.id}`,
        data:payload
        
    }).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao atualizar a notificação'};
      }
    }).catch((error) => {

        throw(error);
    })  
}

export const ActionAddPermission = (_, payload) => {

  return services.notifications.addPermission({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionRemovePermission = (_, payload) => {

 return services.notifications.removePermission({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionDeleteNotification = (_, payload) => {

  const baseUrl = process.env.VUE_APP_ROOT_API
  return services.notifications.deleteNotification({  
      baseURL: baseUrl,
      url: `notifications/${payload}`,
      data:payload
  }).catch((error) => {
    throw(error);
  })  

}