import services from '@/http';


export const ActionGetRoutes = (_, payload) => {

    const urlParams = {
        _dc: '1654548076824',
        deviceId: payload.deviceId,
        type: 'allEvent',
        from: payload.from,
        to: payload.to,
        daily: payload.daily,
        page: 1,
        start: 0,
        limit: 25
    }

    return services.reports.routes({ params: urlParams }).then(res => {
        return res.data

    }).catch((error) => {
        throw error;
    })
}

export const ActionGetTrips = (_, payload) => {

    const urlParams = {
        _dc: '1654548076824',
        deviceId: payload.deviceId,
        type: 'allEvent',
        from: payload.from,
        to: payload.to,
        daily: payload.daily,
        page: 1,
        start: 0,
        limit: 25
    }

    return services.reports.trips({ params: urlParams }).then(res => {
        return res.data

    }).catch((error) => {
        throw error;
    })
}

export const ActionGetStops = (_, payload) => {

    const urlParams = {
        _dc: '1654548076824',
        deviceId: payload.deviceId,
        type: 'allEvent',
        from: payload.from,
        to: payload.to,
        daily: payload.daily,
        page: 1,
        start: 0,
        limit: 25
    }

    return services.reports.stops({ params: urlParams }).then(res => {
        return res.data

    }).catch((error) => {
        throw error;
    })
}

export const ActionGetEvents = (_, payload) => {

    const urlParams = {
        deviceId: payload.deviceId,
        from: payload.from,
        to: payload.to,
        mail: false
    }

    let url = new URLSearchParams(urlParams);

    payload.types.forEach((type) => {
        url.append("type", type);
    });

    const data = url.toString();
    const baseUrl = process.env.VUE_APP_ROOT_API;

    return services.reports.events({
        baseURL: baseUrl,
        url: `reports/events?${data}`,
    }).then(res => {
        return res.data;

    }).catch((error) => {
        throw error;
    })

}

export const ActionGetSummary = (_, payload) => {

    const urlParams = {
        deviceId: payload.deviceId,
        from: payload.from,
        to: payload.to,
        daily: payload.daily,
        mail: false
    }

    return services.reports.summary({ params: urlParams }).then(res => {
        return res.data

    }).catch((error) => {
        throw error;
    })
}