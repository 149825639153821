import semver from 'semver';
import connector from './orionConnector';

    /**
     * Install plugin
     * @param app
     */


    function orion(app,server) {



        if (orion.installed) {
            return;
        }

        if (!server) {
            console.error('SERVER is required');
            return;
        }

        orion.installed = true;


        let orionConnector = new connector(server,app);

        if (semver.lt(app.version, '3.0.0')) {
            Object.defineProperties(app.prototype, {

                orion: {
                    get: function get() {
                        return orionConnector;
                    }
                },

                $orion: {
                    get: function get() {
                        return orionConnector;
                    }
                }

            });
        } else {
            app.config.globalProperties.orion = orionConnector;
            app.config.globalProperties.$orion = orionConnector;
        }

        app.orion = orionConnector;
        app.$orion = orionConnector;
    }


    export default orion;
