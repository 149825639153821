<template>
  <CSidebar 
    class="bg-dark d-print-none"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
    >
    <CSidebarBrand>
        <CImage :src="`${publicPath}images/logo.png`" id="logo" height="35" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'

export default {
    name: 'AppSidebar',
    components: {
      AppSidebarNav,
    },
    setup() {
        const store = useStore()

        return {
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
            publicPath: process.env.BASE_URL
        }
    }
}
</script>
<style  lang="scss">
  .sidebar-brand{
    background: #ced4da !important;
  }
</style>