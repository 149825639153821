import CryptoJS from 'crypto-js';



export function encryptData(data, salt) {

    try {
        if (!data) {
            throw 'Valor local vazio';
        }
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
        const hmac = CryptoJS.HmacSHA256(encrypted, CryptoJS.SHA256(salt)).toString();
        return hmac + encrypted;
    } catch (er) {
        //console.log(er)
        return ''
    }
}



export function decryptData(data, salt) {

    try {
        if (!data) {
            throw 'Valor local vazio';
        }
        const transithmac = data.substring(0, 64);
        const transitencrypted = data.substring(64);
        const decryptedhmac = CryptoJS.HmacSHA256(transitencrypted, CryptoJS.SHA256(salt)).toString();
        const decrypted = CryptoJS.AES.decrypt(transitencrypted, salt).toString(CryptoJS.enc.Utf8);

        if (transithmac != decryptedhmac) {
            throw 'Valor inválido';
        }
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

    } catch (er) {
        //console.log(er);
        return ''
    }
}

