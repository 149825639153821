export default {
    veiculoCons_id: { method: 'post', url: 'veiculoCons_id', port: '5001' },
    veiculoCons_name: { method: 'post', url: 'veiculoCons_name', port: '5001' },
    veiculoInc: { method: 'post', url: 'veiculoInc', port: '5001' },
    veiculoUpdate: { method: 'post', url: 'veiculoUpdate', port: '5001' },
    veiculoDelete: { method: 'post', url: 'veiculoDelete', port: '5001' },
    fotoInc: { method: 'post', url: 'fotoInc', port: '5001' },
    fotoDelete: { method: 'post', url: 'fotoDelete', port: '5001' },
    fotoCons_veiculoId: { method: 'post', url: 'fotoCons_veiculoId', port: '5001' },
    veiculo_dispositivoInc: { method: 'post', url: 'veiculo_dispositivoInc', port: '5001' },
    veiculo_dispositivoDelete: { method: 'post', url: 'veiculo_dispositivoDelete', port: '5001' },
    tipo_veiculoCons_id: { method: 'post', url: 'tipo_veiculoCons_id', port: '5001' },
    tipo_veiculoCons_name: { method: 'post', url: 'tipo_veiculoCons_name', port: '5001' },
    tipo_veiculoInc: { method: 'post', url: 'tipo_veiculoInc', port: '5001' },
    tipo_veiculoUpdate: { method: 'post', url: 'tipo_veiculoUpdate', port: '5001' },
    tipo_veiculoDelete: { method: 'post', url: 'tipo_veiculoDelete', port: '5001' }
}