<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CIcon icon="cil-user"  size="lg" class="mx-2" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Configurações 
      </CDropdownHeader>
      <CDropdownItem @click="this.$router.push({ name : 'Minha Conta' })"> <CIcon icon="cil-user" /> Minha Conta </CDropdownItem>
      <CDropdownItem v-if="userType =='administrator'" @click="this.$router.push({ path : '/usuarios' })"> <CIcon icon="cil-people" /> Usuários </CDropdownItem>
      <CDropdownItem v-if="userType =='administrator'" @click="this.$router.push({ path : '/empresas' })"> <CIcon icon="cil-building" /> Empresas </CDropdownItem>
      <CDropdownItem @click="this.$router.push({ name : 'Gestão de Notificações' })"> <CIcon icon="cil-bell" /> Gestão de Notificações </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="this.$router.push({ name : 'logout' })"><CIcon icon="cil-lock-locked" /> Sair </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
// import avatar from '@/assets/images/avatars/8.jpg'
import { mapGetters} from "vuex";
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      // avatar: avatar,
      // itemsCount: 42,
    }
  },
  computed: {
    ...mapGetters("auth", ["userType"]),
  },
}
</script>
