<template>
<CCallout :color="message.color" :class="message.color">
     <CAlertHeading v-show="message.title"> {{ message.title }} </CAlertHeading>
    <pre>{{ message.msg }}</pre>
</CCallout>
</template>



<script>
export default {
    name: "ShowMessage",
    props:{
        message: Object
    }
}
</script>

<style scoped>
.danger{
    color:var(--cui-danger);
    border-color:var(--cui-danger);
}
.success{
    color:var(--cui-success);
    border-color:var(--cui-success);
}
.info{
    color:var(--cui-info);
    border-color:var(--cui-info);
}
.warning{
    color:var(--cui-warning);
    border-color:var(--cui-warning);
}
.primary{
    color:var(--cui-primary);
    border-color:var(--cui-primary);
}
.secondary{
    color:var(--cui-secondary);
    border-color:var(--cui-secondary);
}
 
</style>