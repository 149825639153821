import axios from 'axios';

export async function searchRoutes(coordinates) {
    if (coordinates != "") {
  
        const instance = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: false
        });

        return instance.get(`http://143.110.147.220:5000/route/v1/driving/${coordinates}`, { params: { geometries: 'geojson', overview:'full', steps:true } })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
            console.log(error);
        })
        
    }
}


