
import { h, resolveComponent } from 'vue'
export default [
  {
    path: '/combustivel',
    name: 'Combustível',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/combustivel/gestao',
    children: [
      {
        path: '/combustivel/ordem',
        name: 'Ordem de Abastecimento',
        component: () => import('./views/homeOrder')
      },
      {
        path: '/combustivel/gestao',
        name: 'Gestão de Combustível',
        component: () => import('./views/homeFuel')
      },
    ]
  },
]