<template>
  <CHeader position="sticky" class="mb-4 d-print-none">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <!-- <CIcon :icon="logo" height="48" alt="Logo" /> -->
              <CImage :src="`${publicPath}images/logo.png`" id="logo" height="35" />

      </CHeaderBrand>
      <!-- <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Users</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">Settings</CNavLink>
        </CNavItem>
      </CHeaderNav> -->
       <CHeaderNav class="d-flex justify-content-center">
        <CNavItem>
          <label>Bem-vindo ao {{ APP_TITLE }}, <b>{{ user.name }}</b>. {{  dateFormatted.time }}, {{ dateFormatted.weekday }}, {{ dateFormatted.day }} de {{ dateFormatted.month }} de {{ dateFormatted.year }} </label>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>
<script>

import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { mapState } from "vuex"

export default {
  name: 'AppHeader',
    components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  data() {
    return {
      date:null
    };
  },
  computed:{
    APP_TITLE: () => process.env.VUE_APP_TITLE,
    ...mapState('auth', ['user']),
    dateFormatted() {
      return this.date;
    }
  },
  created(){
    this.refreshDate(); 
  },
  mounted() {
    setInterval(() => {this.refreshDate();}, 1000);
  },
  methods: {
    refreshDate() {
      this.date = {
        time: new Date().toLocaleString('pt-BR', { timeZone: "America/Sao_Paulo", hour: '2-digit', minute:'2-digit'}),
        weekday: new Date().toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo", weekday: 'long' } ).replace(/^\w/, (c) => c.toUpperCase()),
        day: new Date().toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo", day: 'numeric' }),
        month: new Date().toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo", month: 'long' }).replace(/^\w/, (c) => c.toUpperCase()),
        year: new Date().toLocaleDateString('pt-BR', { timeZone: "America/Sao_Paulo", year: 'numeric' }),
      }
    }
  },
}
</script>


