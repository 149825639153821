
import { h, resolveComponent } from 'vue'
export default [
  {
    path: '/manutencoes',
    name: 'Manutenções',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/manutencoes/gestao',
    children: [
      {
        path: '/manutencoes/gestao',
        name: 'Gestão de Manutenções',
        component: () => import('./views/homeMaintenance')
      },
    ]
  },
]