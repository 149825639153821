import { setOrionToken } from '@/http'
import * as util from '@/helpers/util'

export const setHeaderToken = token => setOrionToken(token);

export const getLocalToken = ()  => util.decryptData(((!sessionStorage.getItem('token')) ? '' : sessionStorage.getItem('token')), process.env.VUE_APP_KEY);
export const deleteLocalToken = () => sessionStorage.removeItem('token');
export const setLocalToken = token =>  sessionStorage.setItem('token', util.encryptData(token, process.env.VUE_APP_KEY));

export const getLocalUser = ()  => util.decryptData(((!sessionStorage.getItem('user')) ? '' : sessionStorage.getItem('user')), process.env.VUE_APP_KEY);
export const deleteLocalUser = () => sessionStorage.removeItem('user');
export const setLocalUser = user => sessionStorage.setItem('user', util.encryptData(user, process.env.VUE_APP_KEY));

export const getLocalOrion = ()  => util.decryptData(((!sessionStorage.getItem('orion')) ? '' : sessionStorage.getItem('orion')), process.env.VUE_APP_KEY);
export const deleteLocalOrion = () => sessionStorage.removeItem('orion');
export const setLocalOrion = orion => sessionStorage.setItem('orion', util.encryptData(orion, process.env.VUE_APP_KEY));

export const getLocalHash = ()  => util.decryptData(((!sessionStorage.getItem('hash')) ? '' : sessionStorage.getItem('hash')), process.env.VUE_APP_KEY);
export const deleteLocalHash = () => sessionStorage.removeItem('hash');
export const setLocalHash = hash =>  sessionStorage.setItem('hash', util.encryptData(hash, process.env.VUE_APP_KEY));

