export default [
  {
    path: '/relatorios/rotas',
    name: 'Histórico de Rotas',
    component: () => import('./views/routesView'),
  },
  {
    path: '/relatorios/paradas',
    name: 'Histórico de Paradas',
    component: () => import('./views/stopsView'),
  },
  {
    path: '/relatorios/viagens',
    name: 'Histórico de Viagens',
    component: () => import('./views/tripsView'),
  },
  {
    path: '/relatorios/eventos',
    name: 'Histórico de Eventos',
    component: () => import('./views/eventsView'),
  },
  {
    path: '/relatorios/resumo',
    name: 'Resumo',
    component: () => import('./views/summaryView'),
  },
  {
    path: '/relatorios/grafico',
    name: 'Gráfico',
    component: () => import('./views/chartsView'),
  }
]