import {servicesApi} from '@/http';
import * as types from './mutation-types';
//import * as storage from '../storage'
import  * as objectsFunctions from '@/helpers/objectFunctions'
import store from '@/store';

export const ActionGetCompanies = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.companies.empresaCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_COMPANIES, res.data.data);
    }

  })
}

export const ActionNewCompany = (_, payload) =>{

    payload.hash = store.state.auth.hash;
    const data = objectsFunctions.ConvertToUrlobj(payload);

    return servicesApi.companies.empresaInc({data:data}).then(res => {
      if(res.data.cod > 0 || !res.data){
        throw {msg:'Erro ao inserir a empresa'};
      }
    })
}

export const ActionUpdateCompany = (_, payload) =>{

    payload.hash = store.state.auth.hash;
    const data = objectsFunctions.ConvertToUrlobj(payload);

    return servicesApi.companies.empresaUpdate({data:data}).then(res => {
      if(res.data.cod > 0 || !res.data){
        throw {msg:'Erro ao editar a empresa'};
      }
    })
}

export const ActionDeleteCompany = (_, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.companies.empresaDelete({  
    data:data
  }).then(res => {
    if(res.data.cod > 0 || !res.data){
      throw {msg:'Erro ao deletar a empresa'};
    }
  })

}

export const ActionComboBoxGroup = ({ state } ) =>{

  let comboBoxList = [
    { label: 'Selecione', value: '', disabled: true }
  ];

  state.companies.map(function(companie) {

    comboBoxList[companie['id']] = { label: companie['nome'], value: companie['id'].toString()}
  });
  
  return comboBoxList;
}