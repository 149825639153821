import services from '@/http'
import {servicesApi} from '@/http';
import * as types from './mutation-types'
import store from '@/store';
//import * as storage from '../storage'
import  * as objectsFunctions from '@/helpers/objectFunctions'

export const ActionGetUsers = ({ commit }, payload) => {

  const urlParams = {};

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }
  return services.users.userList({urlParams}).then(res => {
      
    commit(types.SET_USERS, res.data);

  }).catch((error) => {

      if(error.code == 400){
          throw 'Sem permissão para a listagem de usuários'
      }
      else{
          throw 'Ocorreu algum erro ao tentar realizar a listagem de usuários'
      }
  })  
}


export const ActionNewUser = (_, payload) => {

    return services.users.newUser({data:payload}).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao inserir o usuário'};
      }
    }).catch((error) => {
        throw error;
    })  
}

export const ActionUpdateUser = (_, payload) => {

    const baseUrl = process.env.VUE_APP_ROOT_API
    return services.users.updateUser({  
        baseURL: baseUrl,
        url: `users/${payload.id}`,
        data:payload
        
    }).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao atualizar o usuário'};
      }
    }).catch((error) => {

        throw(error);
    })  
}

export const ActionDeleteUser = (_, payload) => {

  const baseUrl = process.env.VUE_APP_ROOT_API
  return services.users.deleteUser({  
      baseURL: baseUrl,
      url: `users/${payload}`,
      data:payload
  }).catch((error) => {
    throw(error);
  })  

}

export const ActionUsuarioConsId = (_, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuarioCons_id({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      return res.data.data
    }

  })
}

export const ActionUsuarioInc = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);
  
  return servicesApi.users.usuarioInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o usuário'};
    }

    return res.data.last_id;
    
  }) 
}

export const ActionUsuarioUpdate = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuarioUpdate({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o usuário'};
    }
  })  
}

export const ActionUsuarioDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuarioDelete({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao deletar o usuário'};
    }
  })  
}

export const ActionUsuario_dispositivoInc = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuario_dispositivoInc({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao adicionar o usuário ao dispositivo'};
    }
  })  
}

export const ActionUsuario_dispositivoDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;
  
  const data = objectsFunctions.ConvertToUrlobj(payload);
  return servicesApi.users.usuario_dispositivoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao remover o usuário do dispositivo'};
    }
  })  
}

export const ActionUsuario_veiculoInc = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuario_veiculoInc({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao adicionar o usuário ao veículo'};
    }
  })  
}

export const ActionUsuario_veiculoDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuario_veiculoDelete({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao remover o usuário ao veículo'};
    }
  })  
}
export const ActionUsuario_condutorInc= (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuario_condutorInc({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao adicionar o usuário ao condutor'};
    }
  })  
}

export const ActionUsuario_condutorDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.users.usuario_condutorDelete({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao remover o usuário do condutor'};
    }
  })  
}