export default{
    positions: [],
    follow: {
        isFollow:false,
        id: ''
    },
    points: [],
    showPoints: false,
    currentStateReport: '',
    trail: {
        isTrail:false,
        id: ''
    },
}