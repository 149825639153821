import _ from "lodash";

export function fileValidation(data) {

    const file = data.file;

    const extension = file.name.replace(/.*\./, '').toLowerCase();

    if (file.size > data.maxSize) {
        throw 'Arquivo muito grande (> 2MB)';
    }

    if (!_.includes(data.fileTypes, extension)) {
        throw `Tipo de arquivo incorreto! Os tipos de arquivos suportados são: ${data.fileTypes.toString()}`;
    }
}


