<template>
  <CToaster :placement="toaster.placement"> 
    <CToast v-for="(toast, index) in toaster.toasts" :key="index" :color="toaster.color">
      <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <CToastBody>
        {{ toast.content }}
      </CToastBody>
    </CToast>
  </CToaster>
</template>

<script>
export default {
  name: "toastMsg",
  props: {
    toaster: Object,
  }
};
</script>

