import axios from 'axios';

export async function searchAddress(query) {
    if (query != "") {
  
        const instance = axios.create({
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: false
        });

        const APP_KEY = process.env.VUE_APP_API_KEY;

        return instance.get(`https://maps.googleapis.com/maps/api/geocode/json`, { params: { place_id: query, country:'BR', language:'pt-BR', key:APP_KEY} })
            .then(function (response) {
                if(response.data.status == 'OK'){
                    return response.data.results;
                }
                else{
                    throw response.data.error_message;
                }
            })
            .catch(function (error) {
                return error;
        })
        
    }
}


