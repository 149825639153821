export default [
  {
    path: '/usuarios',
    name: 'Gestão de Usuários',
    component: () => import('./views/homeUser'),
  },
  {
    path: '/usuarios/conta',
    name: 'Minha Conta',
    component: () => import('./views/userAccount'),
  }
]