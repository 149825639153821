import {routes as dashboard} from '../views/dashboard'
import {routes as auth} from '../modules/auth'
import {routes as devices} from '../modules/devices'
import {routes as vehicles} from '../modules/vehicles'
import {routes as geolocation} from '../modules/geolocation'
import {routes as drivers} from '../modules/drivers'
import {routes as users} from '../modules/users'
import {routes as geofences} from '../modules/geofences'
import {routes as reports} from '../views/reports'
import {routes as routing} from '../modules/routing'
import {routes as watch} from '../modules/watch'
import {routes as companies} from '../modules/companies'
import {routes as notifications} from '../modules/notifications'
import {routes as fuel} from '../modules/fuel'
import {routes as maintenances} from '../modules/maintenances'

import DefaultLayout from '@/layouts/DefaultLayout'

export default [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
        ...dashboard,
        ...devices,
        ...vehicles,
        ...geolocation,
        ...drivers,
        ...users,
        ...geofences,
        ...reports,
        ...routing,
        ...watch,
        ...companies,
        ...notifications,
        ...fuel,
        ...maintenances
    ],
  },
  ...auth,
  {
    path: '/:pathMatch(.*)*',
    name: 'Página não Encontrada',
    component: () => import(/* webpackChunkName: "pageNotFound" */'@/views/pageNotFound')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */'@/views/signOut/signOut')
  }

]