export default {
    usuarioCons_id: { method: 'post', url: 'usuarioCons_id', port: '5001' },
    usuarioInc: { method: 'post', url: 'usuarioInc', port: '5001' },
    usuarioUpdate: { method: 'post', url: 'usuarioUpdate', port: '5001' },
    usuarioDelete: { method: 'post', url: 'usuarioDelete', port: '5001' },
    usuario_dispositivoInc: { method: 'post', url: 'usuario_dispositivoInc', port: '5001' },
    usuario_dispositivoDelete: { method: 'post', url: 'usuario_dispositivoDelete', port: '5001' },
    usuario_veiculoInc: { method: 'post', url: 'usuario_veiculoInc', port: '5001' },
    usuario_veiculoDelete: { method: 'post', url: 'usuario_veiculoDelete', port: '5001' },
    usuario_condutorInc: { method: 'post', url: 'usuario_condutorInc', port: '5001' },
    usuario_condutorDelete: { method: 'post', url: 'usuario_condutorDelete', port: '5001' },
}