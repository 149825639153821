import { services as auth } from '@/modules/auth'
import { services as geolocation } from '@/modules/geolocation'
import { services as devices } from '@/modules/devices'
import { services as groups } from '@/modules/groups'
import { services as drivers } from '@/modules/drivers'
import { services as users } from '@/modules/users'
import { services as geofences } from '@/modules/geofences'
import { services as reports } from '@/views/reports'
import { services as notifications } from '@/modules/notifications'
export default {
    auth,
    devices,
    geolocation,
    groups,
    drivers,
    users,
    geofences,
    reports,
    notifications
}

