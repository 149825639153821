<template>
  <CFooter class="d-print-none">
    <div>
      <a href="https://dorosistemas.com.br" target="_blank">Doro Sistemas</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} Todos os Direitos Reservados</span>
    </div>
    <div class="ms-auto">
      <a href="https://dorosistemas.com.br"><CImage :src="`${publicPath}images/DORO_LOGO_d.png`" height="20" /></a>
    </div>
    <div class="d-inline ms-2 mt-2">Version:{{appVersion}}</div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  setup() {
    return {
      publicPath: process.env.BASE_URL,
      appVersion: require('../../../package').version
    }
  }
}
</script>