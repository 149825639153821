export default {
    condutorInc: { method: 'post', url: 'condutorInc', port: '5001' },
    condutorUpdate: { method: 'post', url: 'condutorUpdate', port: '5001' },
    condutorDelete: { method: 'post', url: 'condutorDelete', port: '5001' },
    condutorCons_id: { method: 'post', url: 'condutorCons_id', port: '5001' },
    condutorCons_name: { method: 'post', url: 'condutorCons_name', port: '5001' },
    condutor_veiculoInc: { method: 'post', url: 'condutor_veiculoInc', port: '5001' },
    condutor_veiculoUpdate: { method: 'post', url: 'condutor_veiculoUpdate', port: '5001' },
    condutor_veiculoDelete: { method: 'post', url: 'condutor_veiculoDelete', port: '5001' },
    condutor_veiculoCons_CondutorId: { method: 'post', url: 'condutor_veiculoCons_CondutorId', port: '5001' },
    condutor_veiculoCons_VeiculoId: { method: 'post', url: 'condutor_veiculoCons_VeiculoId', port: '5001' },
    condutor_dispositivoInc: { method: 'post', url: 'condutor_dispositivoInc', port: '5001' },
    condutor_dispositivoDelete: { method: 'post', url: 'condutor_dispositivoDelete', port: '5001' },
    documentoInc: { method: 'post', url: 'documentoInc', port: '5001' },
    documentoUpdate: { method: 'post', url: 'documentoUpdate', port: '5001' },
    documentoDelete: { method: 'post', url: 'documentoDelete', port: '5001' },
    documentoCons_idCondutor: { method: 'post', url: 'documentoCons_idCondutor', port: '5001' },
    documento_arquivoInc: { method: 'post', url: 'documento_arquivoInc', port: '5001' },
    documento_arquivoDelete: { method: 'post', url: 'documento_arquivoDelete', port: '5001' },
    documento_arquivoCons_idDocumento: { method: 'post', url: 'documento_arquivoCons_idDocumento', port: '5001' },
    tipo_documentoCons: { method: 'post', url: 'tipo_documentoCons', port: '5001' },
}