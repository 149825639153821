import { servicesApi as auth } from '@/modules/auth'
import { servicesApi as devices } from '@/modules/devices'
import { servicesApi as vehicles } from '@/modules/vehicles'
import { servicesApi as companies } from '@/modules/companies'
import { servicesApi as users } from '@/modules/users'
import { servicesApi as drivers } from '@/modules/drivers'
import { servicesApi as routing } from '@/modules/routing'
import { servicesApi as maintenances } from '@/modules/maintenances'
import { servicesApi as fuel } from '@/modules/fuel'
export default {
    auth,
    devices,
    vehicles,
    companies,
    users,
    drivers,
    routing,
    maintenances,
    fuel
}

