import * as types from './mutation-types'
import {servicesApi} from '@/http';
import * as objectsFunctions from '@/helpers/objectFunctions';
import store from '@/store';

export const ActionGetVehicles = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculoCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_VEHICLES, res.data.data);
    }

  })
}

export const ActionVeiculoCons_id = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculoCons_id({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return null;
    }
    else if(res.status != 200 || !res.data){
      return null;
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionVeiculoInc = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  payload.empresa_id = store.state.auth.orion.empresa_id;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o veículo'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionFotoInc = (_, payload) =>{

  payload.append('hash', store.state.auth.hash);

  const data = payload;

  return servicesApi.vehicles.fotoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao salvar a foto do veículo'};
    }

    return res.data.filename;
    
  })
}

export const ActionFotoDelete = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.fotoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao deletar a foto do veículo'};
    }
    
  })
}

export const ActionVeiculoUpdate = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  //payload.empresa_id = store.state.auth.orion.empresa_id;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculoUpdate({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o veículo'};
    }

    return res.data.last_id;
    
  })
}

export const ActionVeiculoDelete = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculoDelete({  
    data:data
  }).then(res => {
    if(res.data.cod > 0 || !res.data){
      throw {msg:'Erro ao deletar o veículo'};
    }
  })
}

export const ActionVeiculo_dispositivoInc = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculo_dispositivoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao vincular o dispositivo ao veículo'};
    }
  })
}

export const ActionVeiculo_dispositivoDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.veiculo_dispositivoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao remover o vinculo do dispositivo ao veículo'};
    }
  })
}

export const ActionFotoCons_veiculoId = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.fotoCons_veiculoId({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return null;
    }
    else if(res.status != 200 || !res.data){
      return null;
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionComboVehicles = ({ state } ) =>{

  let comboBoxList = [
    { label: 'Selecione', value: '' }
  ];

  state.vehicles.forEach(vehicle => {
    comboBoxList[vehicle['id']] = { label: vehicle['nome'] + '-' +  vehicle['placa'], value: vehicle['id'].toString()}
  });
  
  return comboBoxList;
}

export const ActionGetVehicleTypes = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.tipo_veiculoCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_TYPES, res.data.data);
    }
    else{
      commit(types.SET_TYPES, []);
    }

  })
}

export const ActionComboVehicleTypes = ({ state } ) =>{

  let comboBoxList = [
    { label: 'Selecione', value: '' }
  ];

  state.types.forEach(type => {
    comboBoxList[type['id']] = { label: type['tipo'], value: type['id'].toString()}
  });
  
  return comboBoxList;
}

export const ActionNewType = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.tipo_veiculoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o tipo de veículo'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionUpdateType = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.tipo_veiculoUpdate({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o tipo de veículo'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionDeleteType = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.vehicles.tipo_veiculoDelete({  
    data:data
  }).then(res => {
    if(res.data.cod > 0 || !res.data){
      throw {msg:'Erro ao deletar o tipo de veículo'};
    }
  })
}
