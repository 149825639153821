import * as types from './mutation-types'

export default{
    [types.SET_DEVICES] (state, payload){
        state.devices = payload
    },
    [types.PUT_DEVICE] (state, payload){
        state.devices[payload.key] = payload.value
    },
    [types.PUT_VEHICLE] (state, payload){
        state.devices[payload.key]['vehicle'] = payload.value
    },
    
}