import services from '@/http';
import * as types from './mutation-types';
//import store from '@/store';

export const ActionGetPositions = ({ commit }) => {

    services.geolocation.positions().then(res => {

        commit(types.SET_POSITIONS, res.data)

    }).catch((error) => {

        if (error.code == 400) {
            throw 'Sem permissão para a listagem de dispositivos'
        }
        else {
            throw 'Ocorreu algum erro ao tentar realizar a listagem de dispositivos'
        }
    })
}

export const ActionSetPoints = ({ commit }, payload) => {
    commit(types.SET_POINTS, payload)
}

export const ActionPutPosition = ({ commit }, payload) => {
    commit(types.PUT_POSITION, payload)
}

export const ActionSetFollow = ({ commit }, payload) => {
    commit(types.SET_FOLLOW, payload)
}

export const ActionSetShowPoints = ({ commit }, payload) => {
    commit(types.SET_SHOW_POINTS, payload)
}

export const ActionSetCurrentStateReport = ({ commit }, payload) => {
    commit(types.SET_CURRENT_STATE_REPORT, payload)
}

export const ActionSetTrail = ({ commit }, payload) => {
    commit(types.SET_TRAIL, payload)
}


export const ActionPutAddress = ({ commit }, payload) => {

    const urlParams = {
        _dc: '1654548076824',
        latitude: payload.pos.latitude,
        longitude: payload.pos.longitude
    };


    services.geolocation.geocode({ params: urlParams }).then(res => {

        const obj = {
            key: payload.key,
            value: res.data
        }

        commit(types.PUT_ADDRESS, obj);

    }).catch((error) => {
        console.log(error)
        throw error;
    })
}
