import { h, resolveComponent } from 'vue'

export default [
  {
    path: '/roteirizacao',
    name: 'Gestão de Rotas',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/roteirizacao/gestao',
    children: [
      {
        path: '/roteirizacao/gestao',
        name: 'Roterização',
        component: () => import('./views/routePlanning')
      },
      /*{
        path: '/roteirizacao/associar',
        name: 'Associar Rotas/Dispostivos',
        component: () => import('./views/routePlanning')
      },*/
    ]
  },
]