import { createApp } from 'vue';
import App from './App';
import store from './store';
import router from './router';


import './assets/scss/app.scss';

import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';

import Datepicker from '@vuepic/vue-datepicker';
import Maska from 'maska';
import '@vuepic/vue-datepicker/dist/main.css';
import orion from './http/orionConnector/';

import * as objectFunctions from '@/helpers/objectFunctions';
import * as searchCep from '@/helpers/searchCep';
import * as uploadHelper from '@/helpers/uploadHelper';
import * as searchAddress from '@/helpers/searchAddress';
import * as searchRoutes from '@/helpers/searchRoutes';
import * as generateToken from '@/helpers/generateToken';

import showMessage from "@/components/common/showMessage";
import toastMsg from "@/components/common/toastMsg";

import VueGoogleMaps from '@fawmi/vue-google-maps';
import { Loader } from '@googlemaps/js-api-loader';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.use(Maska);
app.use(orion, process.env.VUE_APP_ROOT_API);
app.use(VueGoogleMaps, {
    load: {
        language: 'pt-BR',
        version: "weekly",
        key: process.env.VUE_APP_API_KEY,
        libraries: "places"
    },
})


app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('DtPick', Datepicker);
app.component('showMessage', showMessage);
app.component('toastMsg', toastMsg);
app.config.globalProperties.$objectFunctions = objectFunctions;
app.config.globalProperties.$searchCep = searchCep;
app.config.globalProperties.$uploadHelper = uploadHelper;
app.config.globalProperties.$searchAddress = searchAddress;
app.config.globalProperties.$searchRoutes = searchRoutes;
app.config.globalProperties.$generateToken = generateToken;
app.config.globalProperties.$loader = new Loader({
    apiKey: process.env.VUE_APP_API_KEY,
    libraries: ["places"],
    language: 'pt-BR',
    version: "weekly",
    id: "__googleMapsScriptId2",
});


app.config.globalProperties.$teste = VueGoogleMaps;

app.mount('#app');