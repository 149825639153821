
import { h, resolveComponent } from 'vue'
export default [
  {
    path: '/veiculos',
    name: 'Veículos',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    redirect: '/veiculos/gestao',
    children: [
      {
        path: '/veiculos/gestao',
        name: 'Gestão de Veículos',
        component: () => import('./views/homeVehicle')
      },
      {
        path: '/veiculos/tipo',
        name: 'Tipos de Veículos',
        component: () => import('./views/homeType')
      },
    ]
  },
]