import * as types from './mutation-types'

export default{
    [types.SET_ROUTES] (state, payload){
        state.routes = payload
    },
    [types.SET_ROUTES_DEVICES] (state, payload){
        state.routesDevice = payload
    },
    [types.SET_POINTS] (state, payload){
        state.points = payload
    },
}