import services from '@/http'
import {servicesApi} from '@/http';
import * as types from './mutation-types'
//import * as storage from '../storage'
import * as objectsFunctions from '@/helpers/objectFunctions';
import store from '@/store';

export const ActionGetDrivers = ({ commit }, payload) => {

  const user = store.state.auth.user;

  const urlParams = {};

  if (user.type == 'default') {
    urlParams.userId = user.id;
  }

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }
  else if (user.type != 'default'){
    urlParams.all = true;
  }

  return services.drivers.driversList({params:urlParams}).then(res => {

    commit(types.SET_DRIVERS, res.data);

  }).catch((error) => {

    if (error.code == 400) {
      throw 'Sem permissão para a listagem de motoristas'
    }
    else {
      throw 'Ocorreu algum erro ao tentar realizar a listagem de motoristas'
    }
  })

}
export const ActionCondutorCons_name = ({ commit }, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutorCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_DRIVERS,res.data.data);
    }
    else{
      commit(types.SET_DRIVERS,[]);
    }
  })
}


export const ActionGetDriversParam = (_, payload) => {

  let urlParams = {};

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }

  return services.drivers.driversList({params:urlParams}).then(res => {

    return res.data;

  }).catch((error) => {
    if (error.code == 400) {
      throw 'Sem permissão para a listagem de motoristas'
    }
    else {
      throw 'Ocorreu algum erro ao tentar realizar a listagem de motoristas'
    }
  })
}

export const ActionNewDriver = (_, payload) => {

  return services.drivers.newDriver({ data: payload.form }).then(res => {
    if (res.data) {
      return true;
    }
    else {
      throw { msg: 'Erro ao inserir o motorista' };
    }
  }).catch((error) => {
    throw error;
  })
}

export const ActionUpdateDriver = (_, payload) => {

  const baseUrl = process.env.VUE_APP_ROOT_API;
  return services.drivers.updateDriver({
    baseURL: baseUrl,
    url: `drivers/${payload.form.id}`,
    data: payload.form

  }).then(res => {
    if (res.data) {

     return true;
    }
    else {
      throw { msg: 'Erro ao atualizar o motorista' };
    }
  }).catch((error) => {

    throw (error);
  })
}

export const ActionCondutorInc = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  payload.empresa_id = store.state.auth.orion.empresa_id;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutorInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o condutor'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionCondutorUpdate = (_, payload) => {

  payload.hash = store.state.auth.hash;

  payload.empresa_id = store.state.auth.orion.empresa_id;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutorUpdate({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o condutor'};
    }

  }).catch((error) => {
      throw error;
  })  
}

export const ActionAddPermission = (_, payload) => {

  return services.drivers.addPermission({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionRemovePermission = (_, payload) => {

 return services.drivers.removePermission({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionDeleteDriver = (_, payload) => {

  const baseUrl = process.env.VUE_APP_ROOT_API;
  return services.drivers.deleteDriver({  
      baseURL: baseUrl,
      url: `drivers/${payload}`,
      data:payload
  }).catch((error) => {
    throw(error);
  })  

}

export const ActionCondutor_veiculoCons_CondutorId = (_, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_veiculoCons_CondutorId({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      return res.data.data
    }

  })
}
export const ActionCondutor_veiculoCons_VeiculoId = (_, payload) => {

  payload.hash = store.state.auth.hash;
  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_veiculoCons_VeiculoId({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      return res.data.data
    }
    else{
      return [];
    }

  })
}

export const ActionCondutor_veiculoInc = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_veiculoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao vincular o condutor ao veículo'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionCondutor_veiculoUpdate = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_veiculoUpdate({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o vincular do condutor ao veículo'};
    }
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionCondutor_veiculoDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_veiculoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao remover o vinculo do condutor do veículo'};
    }
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionCondutor_dispositivoInc = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_dispositivoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao vincular o condutor ao dispositivo'};
    }
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionCondutor_dispositivoDelete = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.condutor_dispositivoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao remover o vinculo do condutor do dispositivo'};
    }
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionGetDocumentTypes = ({commit}) =>{

  const data = objectsFunctions.ConvertToUrlobj({hash: store.state.auth.hash});

  return servicesApi.drivers.tipo_documentoCons({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_DOCUMENT_TYPES, res.data.data);
    }
    else{
      commit(types.SET_DOCUMENT_TYPES, []);
    }

  })
}

export const ActionComboDocumentTypes = ({ state } ) =>{

  let comboBoxList = [
    { label: 'Selecione', value: '' }
  ];

  state.documentTypes.forEach(type => {
    comboBoxList[type['id']] = { label: type['nome'], value: type['id'].toString()}
  });
  
  return comboBoxList;
}

export const ActionDocumentoInc = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.documentoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o documento'};
    }

    return res.data.last_id;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionDocumentoUpdate = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.documentoUpdate({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o condutor'};
    }

  }).catch((error) => {
      throw error;
  })  
}

export const ActionDocumentoArquivoInc = (_, payload) =>{

  payload.append('hash', store.state.auth.hash);

  const data = payload;

  return servicesApi.drivers.documento_arquivoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao salvar o anexo do documenot'};
    }

    return res.data.filename;
    
  })
}

export const ActionDocumentoConsIdCondutor = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.documentoCons_idCondutor({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return [];
    }
    else if(res.status != 200 || !res.data){
      return [];
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionDocumentoArquivoConsIdDocumento = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.documento_arquivoCons_idDocumento({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return [];
    }
    else if(res.status != 200 || !res.data){
      return [];
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionDocumentoArquivoDelete = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.documento_arquivoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao deletar o arquivo do documento'};
    }
    
  })
}

export const ActionDocumentoDelete = (_, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.drivers.documentoDelete({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao deletar o arquivo'};
    }
    
  })
}
