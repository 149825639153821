import services from '@/http';
import * as types from './mutation-types';
//import * as storage from '../storage'
//import  * as objectsFunctions from '@/helpers/objectFunctions'
import store from '@/store';

export const ActionGetGeofences = ({commit}, payload) =>{
  const user = store.state.auth.user;

  const urlParams = {};

  if (user.type == 'default') {
    urlParams.userId = user.id;
  }

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }
  else if (user.type != 'default'){
    urlParams.all = true;
  }
  return services.geofences.geofencesList({params:urlParams}).then(res => {
      
    commit(types.SET_GEOFENCES, res.data);

  }).catch((error) => {

      if(error.code == 400){
          throw 'Sem permissão para a listagem de geocercas'
      }
      else{
          throw 'Ocorreu algum erro ao tentar realizar a listagem de geocercas'
      }
  })  
}

export const ActionGetGeofencesParam = (_, payload) => {

  let urlParams = {};

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }

  return services.geofences.geofencesList({params:urlParams}).then(res => {

    return res.data;

  }).catch((error) => {
    if (error.code == 400) {
      throw 'Sem permissão para a listagem de geocercas'
    }
    else {
      throw 'Ocorreu algum erro ao tentar realizar a listagem de geocercas'
    }
  })
}


export const ActionNewGeofence = (_, payload) =>{

    return services.geofences.newGeofence({data:payload}).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao inserir a geocerca'};
      }
    }).catch((error) => {
        throw error;
    })  
}

export const ActionUpdateGeofence= (_, payload) =>{

    const baseUrl = process.env.VUE_APP_ROOT_API
    return services.geofences.updateGeofence({  
        baseURL: baseUrl,
        url: `geofences/${payload.id}`,
        data:payload
        
    }).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao atualizar a Geocerca'};
      }
    }).catch((error) => {

        throw(error);
    })  
}

export const ActionAddPermission = (_, payload) => {

  return services.geofences.addPermission({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionRemovePermission = (_, payload) => {

 return services.geofences.removePermission({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionDeleteGeofence = (_, payload) => {

  const baseUrl = process.env.VUE_APP_ROOT_API
  return services.geofences.deleteGeofence({  
      baseURL: baseUrl,
      url: `geofences/${payload}`,
      data:payload
  }).catch((error) => {
    throw(error);
  })  

}