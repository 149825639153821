export default [
  {
    component: 'CNavTitle',
    name: process.env.VUE_APP_TITLE,
  },
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-home',
  },
  {
    component: 'CNavTitle',
    name: 'Módulos',
  },
  {
    component: 'CNavItem',
    name: 'Rastreamento',
    to: '/geolocalizacao',
    icon: 'cil-map'
  },
  {
    component: 'CNavGroup',
    name: 'Gestão de Dispositivos',
    to: '/dispositivos',
    icon: 'cil-devices',
    items: [
      {
        component: 'CNavItem',
        name: 'Dispositivos',
        to: '/dispositivos/gestao',
        icon: 'cil-router'
      },
      {
        component: 'CNavItem',
        name: 'Grupos',
        to: '/dispositivos/grupos',
        icon: 'cil-object-group'
      }
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Gestão de Veículos',
    to: '/veiculos',
    icon: 'cil-garage',
    items: [
      {
        component: 'CNavItem',
        name: 'Veículos',
        to: '/veiculos/gestao',
        icon: 'cil-car-alt'
      },
      {
        component: 'CNavItem',
        name: 'Tipos de Veículos',
        to: '/veiculos/tipo',
        icon: 'cil-layers'
      }
    ],
  },
  {
    component: 'CNavItem',
    name: 'Geocercas',
    to: '/geocercas',
    icon: 'cil-location-pin'
  },
  {
    component: 'CNavItem',
    name: 'Condutores',
    to: '/condutores',
    icon: 'cil-contact'
  },
  {
    component: 'CNavGroup',
    name: 'Gestão de Rotas',
    to: '/roteirizacao',
    icon: 'cil-fork',
    items: [
      {
        component: 'CNavItem',
        name: 'Roterização',
        to: '/roteirizacao/gestao',
        icon: 'cil-loop'
      },
      {
        component: 'CNavItem',
        name: 'Associar Rotas/Dispositivos',
        to: '/dispositivos/gestao',
        icon: 'cil-list-high-priority'
      }
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Gestão de Manutenções',
    to: '/manutencao',
    icon: 'cil-calendar',
    items: [
      {
        component: 'CNavItem',
        name: 'Manutenções',
        to: '/manutencoes/gestao',
        icon: 'cil-av-timer'
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Gestão de Combustível',
    to: '/combustivel',
    icon: 'cil-speedometer',
    items: [
      {
        component: 'CNavItem',
        name: 'Ordem de Abastecimento',
        to: '/combustivel/ordem',
        icon: 'cil-pen-alt'
      },
      {
        component: 'CNavItem',
        name: 'Dashboard/Relatórios',
        to: '/combustivel/relatorio',
        icon: 'cil-description'
      },
      {
        component: 'CNavItem',
        name: 'Gestão de Combustível',
        to: '/combustivel/gestao',
        icon: 'cil-object-group'
      }
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Relatórios',
    to: '/relatorios',
    icon: 'cil-file',
    items: [
      {
        component: 'CNavItem',
        name: 'Histórico de Rotas',
        to: '/relatorios/rotas',
        icon: 'cil-history'
      },
      {
        component: 'CNavItem',
        name: 'Histórico de Paradas',
        to: '/relatorios/paradas',
        icon: 'cil-flag-alt'
      },
      {
        component: 'CNavItem',
        name: 'Histórico de Viagens',
        to: '/relatorios/viagens',
        icon: 'cil-globe-alt'
      },
      {
        component: 'CNavItem',
        name: 'Histórico de Eventos',
        to: '/relatorios/eventos',
        icon: 'cil-bell'
      },
      {
        component: 'CNavItem',
        name: 'Resumo',
        to: '/relatorios/resumo',
        icon: 'cil-description'
      },
      {
        component: 'CNavItem',
        name: 'Gráfico',
        to: '/relatorios/grafico',
        icon: 'cil-chart-line'
      }
    ],
  },
]
