//import store from "../store"


const response = response => {
    return response;
}

const error = error => {
    let status = error.response.status;
    if (status === 400) {
        throw { code: status, msg: error.response.data };
    }
    if (status === 401) {
        throw { code: status, msg: 'Não Autorizado' };
    }
    else if (error.response.status === 415) {
        throw { code: status, msg: 'Tipo de mídia não suportada' }
    }

    return error;
}

export { response, error };

