import services from '@/http';
import * as types from './mutation-types'
import {servicesApi} from '@/http';
import * as objectsFunctions from '@/helpers/objectFunctions';
import store from '@/store';

export const ActionGetDevices = ({ commit }, payload) => {

  const user = store.state.auth.user;

  const urlParams = {};

  if(typeof payload === 'object' && payload !== null){
      if(payload.all == true && user.type == 'default'){
          delete payload.all ;
          urlParams.userId = user.id;
      }
      Object.keys(payload).map(p =>{
        urlParams[p] = payload[p];
      });
  }
  else{
      urlParams.userId = user.id;
  }
  
  return services.devices.devicesList({ params: urlParams }).then(res => {

    commit(types.SET_DEVICES, res.data);

  }).catch((error) => {

      if (error.code == 400) {
          throw 'Sem permissão para a listagem de dispostivos'
      }
      else {
          throw 'Ocorreu algum erro ao tentar realizar a listagem de dispostivos'
      }
  })
}

export const ActionGetDevicesParam = (_, payload) => {

  let urlParams = {};

  if(typeof payload === 'object' && payload !== null){
    Object.keys(payload).map(p =>{
      urlParams[p] = payload[p];
    });
  }

  return services.devices.devicesList({params:urlParams}).then(res => {

    return res.data;

  }).catch((error) => {
    if (error.code == 400) {
      throw 'Sem permissão para a listagem de dispostivos'
    }
    else {
      throw 'Ocorreu algum erro ao tentar realizar a listagem de dispositivos'
    }
  })
}

export const ActionPutDevice = ({ commit }, payload) => {
  commit(types.PUT_DEVICE, payload);
}

export const ActionNewDevice = (_, payload) =>{

    return services.devices.newDevice({data:payload}).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao inserir o dispositivo'};
      }
    }).catch((error) => {
        throw error;
    })  
}

export const ActionDispositivoCons_name = ({commit}, payload) =>{

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.dispositivoCons_name({data: data }).then(res => {
    if(parseInt(res.data.cod) == 0){
      commit(types.SET_DEVICES, res.data.data);
    }

  })
}


export const ActionDispositivoInc = (_, payload) => {

  payload.hash = store.state.auth.hash;

  payload.empresa_id = store.state.auth.orion.empresa_id;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.dispositivoInc({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao incluir o dispositivo'};
    }

    return res.data.last_id;

  }).catch((error) => {
      throw error;
  })  
}

export const ActionUpdateDevice = (_, payload) =>{

    const baseUrl = process.env.VUE_APP_ROOT_API
    return services.devices.updateDevice({  
        baseURL: baseUrl,
        url: `devices/${payload.id}`,
        data:payload
        
    }).then(res => {
      if(res.data){
        return true;
      }
      else{
        throw {msg:'Erro ao atualizar o dispositivo'};
      }
    }).catch((error) => {

        throw(error);
    })  
}

export const ActionDispositivoUpdate = (_, payload) => {

  payload.hash = store.state.auth.hash;

  payload.empresa_id = store.state.auth.orion.empresa_id;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.dispositivoUpdate({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao editar o dispositivo'};
    }

    return res.data.last_id;

  }).catch((error) => {
      throw error;
  })  
}

export const ActionVeiculo_dispositivoInc = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.veiculo_dispositivoInc({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao vincular o dispositivo ao veículo'};
    }
  })
}

export const ActionAddPermission = (_, payload) => {

    return services.devices.addPermission({ data: payload }).catch((error) => {
      return error;
    })
}

export const ActionRemovePermission = (_, payload) => {

   return services.devices.removePermission({ data: payload }).catch((error) => {
      return error;
    })
}

export const ActionDeleteDevice = (_, payload) => {

  const baseUrl = process.env.VUE_APP_ROOT_API
  return services.devices.deleteDevice({  
      baseURL: baseUrl,
      url: `devices/${payload}`,
      data:payload
  }).catch((error) => {
    throw(error);
  })  
}

export const ActionDispositivoConsVeiculo_id = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.dispositivoConsVeiculo_id({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return null;
    }
    else if(res.status != 200 || !res.data){
      return null;
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionPutVehicle = ({ commit }, payload) => {
  commit(types.PUT_VEHICLE, payload);
}

export const ActionSendCommand = (_, payload) => {

  return services.devices.sendCommand({ data: payload }).catch((error) => {
    return error;
  })
}

export const ActionDispositivoConsId = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.dispositivoCons_id({data}).then(res => {
    if(parseInt(res.data.cod) > 0){
      return null;
    }
    else if(res.status != 200 || !res.data){
      return null;
    }

    return res.data.data;
    
  }).catch((error) => {
      throw error;
  })  
}

export const ActionDispositivoIgnicao = (_, payload) => {

  payload.hash = store.state.auth.hash;

  const data = objectsFunctions.ConvertToUrlobj(payload);

  return servicesApi.devices.dispositivoIgnicao({data}).then(res => {

    if(parseInt(res.data.cod) > 0){
      throw {msg:res.data.msg};
    }
    else if(res.status != 200 || !res.data){
      throw {msg:'Erro ao enviar comando'};
    }

    return res.data;

  }).catch((error) => {
      throw error;
  })  
}
